<template>
  <Layout>
    <div class="container">
      <h1 class="is-clearfix">
        <span class="is-pulled-left">Code Lists</span>
        <button
          id="add"
          @click="modal = 'new'"
          class="button is-info is-pulled-right"
        >
          Add a new Code List
        </button>
      </h1>
      <section class="main-table-container">
        <table class="table is-striped is-fullwidth">
          <thead>
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Company</td>
              <td>Actions</td>
            </tr>
          </thead>
          <!-- END of thead -->
          <tbody>
            <tr
              v-for="(codeList, index) in codeLists"
              v-bind:key="codeList._id"
            >
              <td>{{ codeList.name }}</td>
              <td>{{ codeList.description }}</td>
              <td>
                {{ codeList.company ? codeList.company.name : 'N/A' }}
              </td>
              <td class="actions">
                <div class="field has-addons">
                  <p class="control">
                    <a
                      class="button tooltip is-tooltip-bottom"
                      data-tooltip="See list"
                      @click="
                        newCodeList = { ...codeLists[index] };
                        modal = 'see';
                      "
                    >
                      <span class="icon">
                        <icon :icon="['fas', 'eye']" />
                      </span>
                    </a>
                  </p>
                  <p class="control">
                    <a
                      @click="
                        newCodeList = { ...codeLists[index] };
                        modal = 'edit';
                      "
                      class="button tooltip is-tooltip-bottom"
                      data-tooltip="Edit list"
                    >
                      <span class="icon">
                        <icon :icon="['fas', 'pencil-alt']" />
                      </span>
                    </a>
                  </p>
                  <p class="control">
                    <a
                      class="button tooltip is-danger is-tooltip-bottom"
                      data-tooltip="Delete form"
                      @click="deleteCodeList(index)"
                    >
                      <span class="icon">
                        <icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </a>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
          <!-- END of tbody -->
        </table>
      </section>
      <div
        class="modal modalContainer"
        :class="{ 'is-active': modal !== '' ? true : false }"
      >
        <div class="modal-background"></div>
        <div class="modal-card modalBody">
          <header v-if="modal !== 'see'" class="modal-card-head modalHeader">
            <p v-if="modal === 'new'" class="modal-card-title">
              Add a new Code List
            </p>
            <p v-if="modal === 'edit'" class="modal-card-title">
              Edit Code List
            </p>
          </header>
          <section class="modal-card-body">
            <section class="hero">
              <div v-if="modal === 'see'" class="hero-body">
                <div class="container">
                  <h1 class="title">
                    {{ newCodeList.name }}
                  </h1>
                  <h2 class="subtitle">
                    {{ newCodeList.description }}
                  </h2>
                </div>
              </div>
            </section>
            <div class="optionsListContainer">
              <div v-if="modal !== 'see'" class="columns main-options">
                <div class="column is-half">
                  <div class="field is-horizontal">
                    <div class="field-label is-small">
                      <label class="label">Name</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <input
                            v-model="newCodeList.name"
                            class="input is-small"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-half">
                  <div class="field is-horizontal">
                    <div class="field-label is-small">
                      <label class="label">Description</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <input
                            v-model="newCodeList.description"
                            class="input is-small"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="column is-half">
                  <div class="field is-horizontal">
                    <div class="field-label is-small">
                      <label class="label" for="company"> Company </label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="select">
                          <select
                            v-model="company"
                            ref="company"
                            name="company"
                            class="input"
                            required="required"
                          >
                            <option
                              v-for="(company, index) in companies"
                              :key="index"
                              :value="company"
                            >
                              {{ company.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <label v-if="modal === 'see'" class="label">Options:</label>
              <div
                class="row"
                v-for="(option, index) in newCodeList.options"
                :key="index"
              >
                <div class="columns is-vcentered">
                  <div class="column is-four-fifths">
                    <div class="field is-horizontal">
                      <div class="field-label is-small">
                        <label class="label">Label</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <div class="control">
                            <input
                              :disabled="modal === 'see'"
                              v-model="option.label"
                              class="input is-small"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-small">
                        <label class="label">Value</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <div class="control">
                            <input
                              :disabled="modal === 'see'"
                              v-model="option.value"
                              class="input is-small"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="modal !== 'see'" class="column">
                    <div class="buttons has-text-centered">
                      <a class="button" @click="removeOption(index)">
                        <span class="icon">
                          <icon :icon="['fas', 'minus-circle']" />
                        </span>
                      </a>
                      <a class="button" @click="addOption(index + 1)">
                        <span class="icon">
                          <icon :icon="['fas', 'plus-circle']" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-danger" @click="closeModal()">
              {{ modal === 'new' || modal === 'edit' ? 'Cancel' : 'Close' }}
            </button>
            <button
              v-if="modal !== 'see'"
              class="button is-success"
              @click="actions()"
            >
              {{ modal === 'new' ? 'Add' : 'Update' }}
            </button>
          </footer>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import axios from 'axios';

export default {
  name: 'codeLists',
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  data() {
    return {
      codeLists: [],
      newCodeList: {
        name: '',
        description: '',
        options: [
          {
            label: '',
            value: ''
          }
        ],
        company: ''
      },
      modal: '',
      companies: [],
      company: {},
      userCompanyId: null
    };
  },
  computed: {
    isAdmin() {
      return (
        (this.user && this.user.role && this.user.role === 'admin') || false
      );
    }
  },
  async created() {
    this.user = $cookies.get($formsConfig.core.cookieName);
    // Fetch user info.
    this.userCompanyId = $cookies.get($formsConfig.core.cookieName).company;
    let response;
    // Fetch the list of the companies according to user permissions.
    if (this.isAdmin) {
      response = await axios.get($formsConfig.core.api.companies);
      this.companies = response.data || [];
    } else {
      response = await axios.get(
        $formsConfig.core.api.companies + this.userCompanyId
      );
      this.companies = [response.data] || [];
    }
    this.company = this.companies.find(company => {
      return company._id === this.userCompanyId;
    });
    // filter out codeLists
    try {
      response = await axios.get($formsConfig.core.api.codeLists);
      // fill code lists
      this.codeLists = this.isAdmin
        ? response.data
        : response.data.filter(codeList => {
            return (
              (codeList &&
                codeList.company &&
                codeList.company._id === this.userCompanyId) ||
              false
            );
          });
    } catch (err) {
      this.$notify({
        group: 'alert',
        title: 'Something went wrong!',
        text:
          (err && err.message) ||
          'We were unable to get your code lists. Please try again.',
        type: 'error'
      });
    }
  },
  methods: {
    addOption(index) {
      this.newCodeList.options.splice(index, 0, {
        label: '',
        value: ''
      });
    },
    removeOption(index) {
      if (this.newCodeList.options.length === 1) {
        return;
      }
      this.newCodeList.options.splice(index, 1);
    },
    closeModal() {
      this.newCodeList.name = '';
      this.newCodeList.description = '';
      this.newCodeList.options = [
        {
          label: '',
          value: ''
        }
      ];
      this.modal = '';
    },
    actions() {
      switch (this.modal) {
        case 'new':
          this.newCodeList.company = this.company._id;
          axios
            .post($formsConfig.core.api.codeLists, this.newCodeList)
            .then(response => {
              this.codeLists.push({
                ...response.data,
                company: {
                  company: this.company._id,
                  name: this.company.name
                }
              });
              this.closeModal();
            })
            .catch(err =>
              this.$notify({
                group: 'alert',
                title: 'Something went wrong!',
                text:
                  (err && err.message) ||
                  'We were unable to add the new code list. Please try again.',
                type: 'error'
              })
            );
          break;
        case 'edit':
          this.newCodeList.company = this.company._id;
          axios
            .patch($formsConfig.core.api.codeLists, this.newCodeList)
            .then(response => {
              this.codeLists[
                this.codeLists.findIndex(
                  list => list._id === this.newCodeList._id
                )
              ] = {
                ...this.newCodeList,
                company: {
                  company: this.company._id,
                  name: this.company.name
                }
              };
              this.closeModal();
            })
            .catch(err =>
              this.$notify({
                group: 'alert',
                title: 'Something went wrong!',
                text:
                  (err && err.message) ||
                  'We were unable to update the code list. Please try again.',
                type: 'error'
              })
            );
          break;
      }
    },
    deleteCodeList(index) {
      axios
        .delete(
          `${$formsConfig.core.api.codeLists + this.codeLists[index]._id}`
        )
        .then(respose => this.codeLists.splice(index, 1))
        .catch(err =>
          this.$notify({
            group: 'alert',
            title: 'Something went wrong!',
            text:
              (err && err.message) ||
              'We were unable to delete the code list. Please try again.',
            type: 'error'
          })
        );
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  > .columns {
    height: 100%;
    margin-top: 0;
    > .column {
      height: 100%;
    }
  }
}
.headers {
  font-size: 14px;
  font-weight: bold;
  color: $tittle-font;
}
.optionsListContainer {
  display: flex;
  flex-direction: column;
  max-height: 28rem;
  padding: 0.75rem;
  overflow-y: auto;
  background: $background;
  border: thin solid $border-color;
  border-radius: $control-radius;

  .row {
    margin-bottom: 0.5rem;
    border-bottom: thin solid $border-color;

    &:last-child {
      border: none;
    }

    .field {
      margin-bottom: 0.25rem;
    }
  }

  .columns.main-options {
    flex-wrap: wrap;
  }
}
</style>
